@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Raleway:400,700,900&display=swap&subset=latin-ext');
@import "variables";

html {
  font-size: 12px;

  @include lg() {
    font-size: 16px;
  }
}

h1, h2, h3, h4 {
  font-family: $header-font;
}

h1 {
  font-size: 2.5rem;
}

p {
  line-height: 1.5rem;
}

a.no-style, a.no-style:hover, a.no-style:active {
  text-decoration: inherit;
  color: inherit;
}

li {
  margin-bottom: .5rem;
}

body {
  margin: 0;
  font-family: $text-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sprint-colored {
  color: $sprint-color;
}