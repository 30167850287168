@import "variables";

.Button {
  display: inline-block;

  background-color: $sprint-color;
  padding: 1rem;

  &, &:hover, &:active {
    color: white;
    text-decoration: none;
  }

  &:hover, &:active {
    background-color: scale-color($sprint-color, 10);
  }

  &.large {
    font-size: 1.25rem;
    font-weight: 700;
  }
}