@import "variables";

.IconBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 1rem;

  &.top {
    flex-direction: column;
  }

  &.left {
    width: 100%;
    flex-direction: row;

    .icon {
      margin-right: 2rem;
    }

    .content {
      width: 100%;
      text-align: left;
    }
  }

  .icon {
    width: 10rem;
  }

  .content > *:last-child {
    margin-bottom: 0;
  }
}